* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: Inter, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1a1a1a !important;
}

/* Global scrollbar styles */
*::-webkit-scrollbar {
  width: 9px;
}

*::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.12); /* Equivalent to #FFFFFF1F */
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.3); /* Equivalent to #FFFFFF4D */
  border-radius: 4px;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: rgba(255, 255, 255, 0.35); /* Equivalent to #FFFFFF59 */
}
