/* // NOTE: the order of these styles DO matter */

.DateInput {
    padding: 2.5px 1px;
    background-color: transparent !important;
}

.DateInput_input {
    background-color: transparent;
    color: white;
}

.DateRangePicker {
    width: 100%;
}

.DateRangePickerInput {
    width: 100%;
    background-color: transparent !important;
    /* height: 38px !important; */
    border: 1px solid #5b5b5b;
}

.CalendarMonthGrid__horizontal {
    background-color: black;
}

.CalendarMonth {
    background-color: black !important;
}

.CalendarDay__default {
    background: transparent;
    color: gray;
}

.CalendarDay__default:hover {
    background: transparent !important;
}

.DayPicker_transitionContainer__horizontal {
    background-color: black;
}

.DayPickerNavigation_button__horizontalDefault {
    background: #1d1d1d;
}

.DayPickerKeyboardShortcuts_show__bottomRight {
    display: none;
}

.DateRangePickerInput_calendarIcon {
    padding: 0;
    margin: 0 5px 5px 10px;
}

.DateRangePicker_picker {
    z-index: 2;
}

.DateInput_input__focused {
    border-bottom: 2px solid #cc9c33;
}

.DayPickerKeyboardShortcuts_show__bottomRight::before {
    border-right: 33px solid #cc9c33;
}

.DayPickerKeyboardShortcuts_show__bottomRight:hover::before {
    border-right: 33px solid #cc9c33;
}

.CalendarMonth_caption {
    font-size: 14px;
    line-height: 1.6;
}

.CalendarDay {
    border: unset;
}

/* // Will edit everything selected including everything between a range of dates */
.CalendarDay__selected_span {
    background: #cc9c3333;
    border: unset; /* //default styles include a border */
}

.CalendarDay__blocked_out_of_range,
.CalendarDay__blocked_out_of_range:active,
.CalendarDay__blocked_out_of_range:hover {
    opacity: 0.3;
}

.CalendarDay__selected_span:hover {
    background: #cc9c3333;
}

/* // Will edit selected date or the endpoints of a range of dates */
.CalendarDay__selected {
    background: #cc9c33;
    border: unset;
    border-radius: 5px;
}

/* // Will edit when hovered over. _span style also has this property */
.CalendarDay__selected:hover {
    background: #cc9c33;
}

/* // Will edit when the second date (end date) in a range of dates
// is not yet selected. Edits the dates between your mouse and said date */
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
    background: #cc9c3333;
    border: unset;
}
